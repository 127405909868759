<template>
  <header class="autor-header">
    <article class="autor-header__left">
      <h1 class="autor-header__name">{{ nombre }}</h1>
      <div class="autor-header__summary">
        <slot name="bio"></slot>
      </div>
      <div class="autor-header__social">
        <div class="autor-header__social-network" v-if="linkedin">
          <a :href="linkedin" target="_blank" rel="noopener noreferrer">
            <img src="../../../assets/img/ico/ico-social-color-linkedin.svg" alt="LinkedIn" />
          </a>
        </div>
        <div class="autor-header__social-network" v-if="goodreads">
          <a :href="goodreads" target="_blank" rel="noopener noreferrer">
            <img src="../../../assets/img/ico/ico-social-color-goodreads.svg" alt="Goodreads" />
          </a>
        </div>
        <div class="autor-header__social-network" v-if="amazon">
          <a :href="amazon" target="_blank" rel="noopener noreferrer">
            <img src="../../../assets/img/ico/ico-social-color-amazon.svg" alt="Amazon" />
          </a>
        </div>
        <div class="autor-header__social-network" v-if="medium">
          <a :href="medium" target="_blank" rel="noopener noreferrer">
            <img src="../../../assets/img/ico/ico-social-color-medium.svg" alt="Medium" />
          </a>
        </div>
        <div class="autor-header__social-network" v-if="web">
          <a :href="web" target="_blank" rel="noopener noreferrer">
            <img src="../../../assets/img/ico/ico-social-color-www.svg" alt="Website" />
          </a>
        </div>
      </div>
    </article>
    <aside class="autor-header__picture">
      <picture class="autor-header__avatar">
        <slot name="avatar"></slot>
      </picture>
    </aside>
  </header>
</template>

<script>
export default {
  name: "AutorHeader",
  props: {
    nombre: String,
    linkedin: String,
    goodreads: String,
    amazon: String,
    medium: String,
    web: String,
  },
};
</script>
