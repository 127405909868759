<template>
  <article class="autor-timeline-item">
    <time class="autor-timeline-item__date">
      {{ periodo }}
    </time>
    <div class="autor-timeline-item__titulo">
      {{ titulo }}
    </div>
    <div class="autor-timeline-item__descripcion">
      <slot></slot>
    </div>
  </article>
</template>

<script>
export default {
  name: "AutorTimeline",
  props: {
    periodo: String,
    titulo: {
      type: String,
      default: "Título experiencia",
    },
  },
};
</script>

<style lang="scss" scoped></style>
