<template>
  <div class="page-autor">
    <div class="bb-container">
      <div class="page-autor__container">
        <div class="page-autor__header">
          <AutorHeader
            :nombre="author ? author.name : 'Cargando...'"
            linkedin="https://www.linkedin.com/in/antonellagrandinetti/"
            goodreads="https://www.goodreads.com/author/show/16225366.Antonella_Grandinetti"
            amazon="https://www.amazon.es/stores/Antonella-Grandinetti/author/B01N4AVESS?ref=ap_rdr&isDramIntegrated=true&shoppingPortalEnabled=true"
          >
            <template slot="avatar">
              <img :src="author.profile_image" :alt="author.name" />
            </template>
            <template slot="bio">
              <!--              {{ user.author ? user.author.bio : 'Cargando biografía...' }}-->
              <p>
                ¡Hola! Soy Antonella, una apasionada escritora y experta en marketing
                digital. Mi viaje académico comenzó en la Universidad Argentina de la
                Empresa, donde me gradué en Relaciones Públicas e Institucionales en 2005,
                y más tarde obtuve mi grado en Publicidad en 2007.
              </p>

              <p>
                Además de mi carrera profesional, soy madre de tres niños encantadores (y
                muy movedizos), de entre 4 y 12 años. La maternidad no solo ha enriquecido
                mi vida personal, sino que también ha guiado mi camino profesional. Desde
                que me convertí en madre, mi interés por la puericultura y el universo
                infantil se ha vuelto una pasión. ¡Incluso he realizado cursos de modelado
                en fondant y tartas infantiles para hacer los cumpleaños de mis peques muy
                especiales!
              </p>
              <p>
                Mi amor por la escritura y por mis hijos me llevó a explorar un nuevo
                mundo: el de los libros infantiles. He tenido el honor de publicar cinco
                cuentos infantiles, como "¿Dónde estás abuelito?" y "Mateo descubre las
                emociones", así como un libro humorístico sobre la maternidad titulado
                "Diario de una mala madre". También he escrito novelas juveniles como
                "Secretos en el aire" y "Secretos en mis canciones".
              </p>
              <p>
                Mi objetivo es seguir explorando y compartiendo experiencias a través de
                la escritura, brindando a los lectores información que inspire, ayude y,
                también, entretenga.
              </p>
            </template>
          </AutorHeader>
        </div>

        <div class="page-autor__experiencia">
          <AutorExperiencia>
            <p>
              A lo largo de mi carrera, me he especializado en desarrollar estrategias de
              comunicación en el mundo digital con todas las herramientas que este ofrece.
              Cuento con una experiencia amplia y variada en campos como la redacción de
              contenidos, comunicación en redes sociales, SEO, diseño web y estrategias de
              generación de enlaces.
            </p>
            <p>
              Con una sólida experiencia como ejecutiva de cuentas nacionales e
              internacionales en consultoras de comunicación, en 2020 di un salto y fundé
              mi propia empresa de marketing digital. Colaboro con diversos clientes,
              aplicando mi experiencia para potenciar su presencia en línea.
            </p>
          </AutorExperiencia>
        </div>

        <div class="page-autor__content">
          <AutorTimeline titulo="Estudios" ico="uil-book-open">
            <AutorTimelineItem titulo="Graduada en Publicidad" periodo="2005-2007">
              <p>Universidad Argentina de la Empresa</p>
            </AutorTimelineItem>
            <AutorTimelineItem
              titulo="Graduada en relaciones Públicas e Institucionales"
              periodo="2002-2005"
            >
              <p>Universidad Argentina de la Empresa</p>
            </AutorTimelineItem>
          </AutorTimeline>
          <div class="autor-otras">
            <div class="autor-otras__title">Otras publicaciones</div>
            <ul class="autor-otras__items">
              <li>
                <a
                  target="_blank"
                  href="https://www.amazon.es/Diario-mala-madre-Antonella-Grandinetti-ebook/dp/B06XD337X7"
                  >Diario de una mala madre
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://www.amazon.es/tener-hermanito-Embarazo-¡para-niños-ebook/dp/B06XHSK7QB"
                  >Voy a tener un hermanito</a
                >
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://www.amazon.es/hermanito-camino-Embarazo-%C2%A1para-ni%C3%B1os-ebook/dp/B06X9QZFBD"
                  >Mi hermanito está en camino</a
                >
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://www.amazon.es/Torbellino-emociones-Mis-n%C2%BA-ebook/dp/B06ZY9TRM2"
                  >Torbellino de emociones</a
                >
              </li>
            </ul>
          </div>
          <div class="autor-posts">
            <div class="autor-posts__title">Publicaciones en HelloBB</div>
            <div class="autor-posts__items">
              <!-- TODO: Aqui van los posts del autor  -->
              <BlogPostThumb
                class="thumb-post--related"
                v-for="(post, index) in authorPosts.slice(0, displayedPosts)"
                :key="index"
                :post="post"
              />
            </div>
            <div class="autor-posts__actions" v-if="showLoadMoreButton">
              <bbButton
                label="Ver más publicaciones"
                class="button--outline-dark"
                icoEnd="uil-angle-right"
                @click="loadAllPosts"
              ></bbButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AutorHeader from "./components/AutorHeader";
import AutorTimeline from "./components/AutorTimeline";
import AutorExperiencia from "./components/AutorExperiencia.vue";
import AutorTimelineItem from "./components/AutorTimelineItem";
import BlogPostThumb from "./components/BlogPostThumb.vue";
import bbButton from "../../components/buttons/bbButton.vue";
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  name: "AntonellaAutor",
  components: {
    AutorHeader,
    AutorTimeline,
    AutorTimelineItem,
    AutorExperiencia,
    BlogPostThumb,
    bbButton,
  },
  metaInfo() {
    return {
      title: "Autores en HelloBB: Antonella Grandinetti",
      meta: [
      { name: "description", content: "Antonella es autora de cinco libros de cuentos infantiles y un libro humorístico sobre la maternidad. Redactora creativa, graduada en RRPP y mamá." },
      ]
    };
  },
  data() {
    return {
      author: {
        name: "",
        profile_image: "",
        bio: "",
        url: "",
      },
      authorPosts: [],
      limit: 15,
      displayedPosts: 15,

      showLoadMoreButton: true,
    };
  },
  async mounted() {
    await this.fetchAutorData();
    await this.fetchAutorPosts();
  },
  methods: {
    async fetchAutorData() {
      try {
        const slug = "antonella";
        const ghostApiUrl = `https://hellobb.ghost.io/ghost/api/v3/content/authors/slug/${slug}?key=0a666181ef624b5781de4486c8`;
        const response = await axios.get(ghostApiUrl);

        this.author = response.data.authors[0];
      } catch (error) {
        console.error("Error al obtener el autor del blog:", error);
      }
    },
    loadAllPosts() {
      this.displayedPosts += 15;
      if (this.displayedPosts >= this.authorPosts.length) {
        this.showLoadMoreButton = false;
      }
    },
    async fetchAutorPosts() {
      try {
        const slug = "antonella";
        const url = `https://hellobb.ghost.io/ghost/api/v3/content/posts/?key=0a666181ef624b5781de4486c8&filter=authors:${slug}&include=authors,tags&limit=all`;

        const postsResponse = await axios.get(url);
        this.authorPosts = postsResponse.data.posts;

        this.showLoadMoreButton = this.authorPosts.length > 15;
      } catch (error) {
        console.error("Error al obtener posts del autor:", error);
      }
    },

  },
};
</script>

<style lang="scss" scoped></style>
