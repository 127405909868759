<template>
  <section class="autor-experiencia">
    <div class="autor-timeline__header">
      <i class="uil uil-bag-alt"></i>
      <span>{{ titulo }}</span>
    </div>

    <div class="autor-experiencia__content">
      <slot></slot>
    </div>
  </section>
</template>

<script>
export default {
  name: "AutorExperiencia",
  props: {
    titulo: {
      type: String,
      default: "Experiencia",
    },
  },
};
</script>

<style lang="scss" scoped></style>
