<template>
  <section class="autor-timeline">
    <div class="autor-timeline__header">
      <i class="uil" :class="ico"></i>
      <span>{{ titulo }}</span>
    </div>
    <div class="autor-timeline__items">
      <div class="autor-timeline__line">
        <div class="timeline-line">
          <div class="timeline-line__item timeline-line__item--top">
            <img src="../../../assets/img/ico/ico-timeline-top.svg" alt="" />
          </div>
          <div class="timeline-line__item timeline-line__item--middle">
            <!-- <img src="../../../assets/img/ico/ico-timeline-middle.svg" alt="" /> -->
          </div>
          <div class="timeline-line__item timeline-line__item--bottom">
            <img src="../../../assets/img/ico/ico-timeline-bottom.svg" alt="" />
          </div>
        </div>
      </div>
      <div class="autor-timeline__content">
        <slot></slot>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "AutorTimeline",
  props: {
    titulo: String,
    ico: {
      type: String,
      default: "uil-bag-alt",
    },
  },
};
</script>

<style lang="scss" scoped></style>
